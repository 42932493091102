import { gql } from 'graphql-request';
import fragmentCartMutationResponse from 'service/fragments/cartFragmentMutationResponse';

const removeCartItemMutation = gql`
  mutation ($cartId: String!, $cartItemId: ID!, $scheduledTime: ISO8601DateTime) {
    deleteItemFromCart(cartId: $cartId, cartItemId: $cartItemId) {
      cart {
        ...returnedCart
      }
    }
  }
  ${fragmentCartMutationResponse}
`;

export default removeCartItemMutation;
