import React, { useState } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { Button } from '@zydalabs/zac-react';
import { DATA_LAYER_CONTENT_TYPE, DEBOUNCE_RATE, FACEBOOK_PIXEL_EVENTS, LANGUAGE_AR, SNAPCHAT_EVENTS, TIKTOK_EVENTS } from 'common/constants';
import { debounce, normalizeFulfillmentMode, trackFacebookEvent, trackTiktokEvents } from 'common/utils';
import {
  useGlobalInitializingCart,
  usePersistedBranchId,
  usePersistedCountryDetails,
  usePersistedSupportedDeliveryZone,
  useUserData,
} from 'common/hooks';
import { CustomActionsRegistry } from 'common/lib/registries';
import { CustomActionsEnum } from 'common/enums';
import { translateDisplayPrice } from 'modules/productsModule/utils';
import { useAddToCart, useRemoveFromCart, useUpdateItemInCart } from 'modules/productsModule/hooks';
import { pushAddToCartEventToDataLayer } from 'common/lib/tracking/utils';
import ServiceConfigs from 'service/config';
import { ShoppingBagIcon } from '@zydalabs/zac-icons-react';
import { useCreateFacebookCapi } from 'service/hooks';
import { trackSnapchatEvents } from 'common/utils/trackSnapchatEvents';
import { StyledCounterWrapper } from './style';
import { CardButtonPropType } from './types';
import Counter from '../Counter';
import { useFulfillmentSettings } from '../../../../contexts';

const CardButton: React.FC<CardButtonPropType> = ({
  uuid,
  cartItemId,
  isQuickAddToCartEnabled,
  mode,
  variantQuantity,
  firstVariantId = '',
  firstVariantName = '',
  firstVariantPrice = 0.0,
  stockCount,
  displayPrice,
  isProductUnAvailable,
  setShouldInvalidateProduct,
  price,
  highestDiscountedPercentage,
  title,
  isFoodCard,
}) => {
  const router = useRouter();
  const { t, lang } = useTranslation('product');
  const [count, setCount] = useState<number>(variantQuantity);
  const useDeliveryzonesMs = ServiceConfigs?.getUseDeliveryzonesMs();
  const { area } = useFulfillmentSettings();
  // persisted data
  const [persistedCountryDetails] = usePersistedCountryDetails();
  const [supportedDeliveryZone] = usePersistedSupportedDeliveryZone();
  const { userDataBackEnd, capiEnabled, tiktokData } = useUserData();

  const [branchId] = usePersistedBranchId();

  const [isInitializingCart] = useGlobalInitializingCart();

  const currency = persistedCountryDetails?.currency?.[`title_${router.locale}`];
  const currencyEn = persistedCountryDetails?.currency?.title_en;
  const isArabic = router.locale === LANGUAGE_AR;
  // mutations
  const addItemToCart = useAddToCart({
    branchId,
    deliveryType: normalizeFulfillmentMode(mode),
    // TODO :: Check if areaID should be sent conditionally
    areaId: area?.id,
    ...(useDeliveryzonesMs &&
      !!supportedDeliveryZone && {
      deliveryZone: {
        ...supportedDeliveryZone,
      },
    }),
  });
  const updateCartItem = useUpdateItemInCart();
  const removeCartItem = useRemoveFromCart();
  const createCapiEvent = useCreateFacebookCapi();

  const getDiscountedPrice = (fullPrice, discountPercentage) => {
    if (!discountPercentage) return fullPrice;
    return fullPrice - fullPrice * discountPercentage * 0.01;
  };

  const handleUpdateRemoveCartItem = async newCount => {
    const isRemoving = newCount < count;
    setCount(newCount);
    const quickUpdateRemoveCartItemCallback = async () => {
      const itemPrice =
        (price && getDiscountedPrice(price, highestDiscountedPercentage)) ||
        (firstVariantPrice && getDiscountedPrice(firstVariantPrice, highestDiscountedPercentage));
      CustomActionsRegistry.setCustomAction({
        key: CustomActionsEnum.MutateCartDetails,
        fn: () => setShouldInvalidateProduct(true),
      });
      if (newCount === 0) {
        await removeCartItem(cartItemId);
      } else if (!variantQuantity && !!newCount)
        await addItemToCart({ menuItemId: uuid, variantQuantity: newCount, variantId: firstVariantId });
      else await updateCartItem({ cartItemId, menuItemId: uuid, variantId: firstVariantId, variantQuantity: newCount });
      if (newCount !== 0 && !isRemoving) {
        trackFacebookEvent(
          FACEBOOK_PIXEL_EVENTS.ADD_TO_CART,
          {
            content_ids: uuid,
            content_type: DATA_LAYER_CONTENT_TYPE.PRODUCT,
            currency: currencyEn,
            value: itemPrice || 0,
            content_name: title,
          },
          userDataBackEnd,
          createCapiEvent,
          capiEnabled,
        );
        trackSnapchatEvents(
          SNAPCHAT_EVENTS.ADD_TO_CART,
          {
            currency: currencyEn,
            price: itemPrice || 0,
            content_name: title,
            item_ids: [uuid],
            item_category: title,
            number_items: 1
          },
        );
        trackTiktokEvents(
          TIKTOK_EVENTS.ADD_TO_CART,
          {
            currency: currencyEn,
            value: itemPrice || 0,
            content_type: "product",
            content_id: uuid,
            quantity: 1
          }, tiktokData
        );
        pushAddToCartEventToDataLayer({
          contents: [{ item_id: uuid, quantity: 1, item_variant: firstVariantName, item_name: title }],
          content_type: DATA_LAYER_CONTENT_TYPE.PRODUCT,
          currency: currencyEn,
          value: itemPrice || 0,
        });
      }
    };
    debounce(quickUpdateRemoveCartItemCallback, DEBOUNCE_RATE);
  };

  return (
    <>
      {count > 0 && isQuickAddToCartEnabled && (
        <StyledCounterWrapper
          onClick={e => {
            e.stopPropagation();
          }}
          isFoodCard={isFoodCard}
        >
          <Counter
            count={count}
            onChange={newCount => {
              handleUpdateRemoveCartItem(newCount);
            }}
            isLoading={isInitializingCart}
            disabled={isProductUnAvailable || isInitializingCart}
            maxCount={stockCount}
          />
        </StyledCounterWrapper>
      )}
      {!(isQuickAddToCartEnabled && count) && (
        <Button
          variant="tertiary"
          isFluid={!isFoodCard}
          text={displayPrice && translateDisplayPrice(displayPrice, t, lang, currency, isArabic)}
          isDisabled={isProductUnAvailable || isInitializingCart}
          onClick={e => {
            if (isQuickAddToCartEnabled) {
              e.stopPropagation();
              handleUpdateRemoveCartItem(1);
            }
          }}
          endIcon={!!isQuickAddToCartEnabled && <ShoppingBagIcon width={20} />}
          size="small"
          testId="card-action-btn"
        />
      )}
    </>
  );
};

export default CardButton;
