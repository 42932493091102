import styled from 'styled-components';

// TODO we should not override button we use from styled component with custom css we need to review it with design system
export const StyleGroceryCard = styled.div<{ isLoading: boolean }>`
  width: 47%;
  min-width: 180px;
  margin-bottom: 16px;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: ${props => props.theme.color.white};
  border-radius: ${props => props.theme.space[1]}px;
  border: solid 1px ${props => props.theme.color.border};
  cursor: pointer;
  ${props => props.isLoading && 'margin: 4px'};

  @media screen and (min-width: calc(${props => props.theme.screenBreakpoints.medium} + 0.2px)) and (max-width: calc(${props =>
      props.theme.screenBreakpoints.large} + 0.2px)) {
    width: 32%;
  }

  & img {
    width: 100%;
  }
`;

export const StyledPhotoWrapper = styled.div<{ dimImage: boolean }>`
  width: 100%;
  ${({ dimImage }) => dimImage && `filter: opacity(50%);`}
  position: relative;
  min-height: 120px;
  & div,
  img {
    min-width: 144px;
    border-radius: ${({ theme }) => theme.space[1]}px;
    aspect-ratio: 1;
    @supports not (aspect-ratio: 1) {
      padding-top: 100%;
      height: 0;
      position: relative;
      overflow: hidden;
    }
    width: 100%;
    height: 120px;
  }
`;

export const StyledGroceryDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  height: 100%;
  padding: ${({ theme }) => `${theme.space[4]}px`};
`;

export const StyledTitleWrapper = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${({ theme }) => `${theme.space[2]}px`};
`;
