import React from 'react';
import { Tag } from '@zydalabs/storefront-components';
import { Typography, useTheme as useZacTheme, Box } from '@zydalabs/zac-react';
import { NextImage } from 'common/components';
import { StyledAvailabilityTag } from 'common/styles';
import { IMAGE_PRIORITY_INDEX_THRESHOLD } from 'common/constants';
import { StyleGroceryCard, StyledGroceryDetailsWrapper, StyledPhotoWrapper, StyledTitleWrapper } from './style';
import { ProductCardUIType } from '../ProductCard/types';

const GroceryCard: ProductCardUIType = ({
  onClick,
  uuid,
  isAvailable,
  title,
  highestDiscount,
  children,
  photoUrl,
  availabilityTag,
  index,
}) => {
  const { colors } = useZacTheme();
  //  TODO this should be handled another way or removed
  return (
    <StyleGroceryCard onClick={onClick}>
      <StyledPhotoWrapper dimImage={!isAvailable}>
        <NextImage
          alt={`${title}-image`}
          src={photoUrl}
          objectFit="cover"
          layout="fill"
          type="thumbnail"
          priority={index < IMAGE_PRIORITY_INDEX_THRESHOLD}
        />
      </StyledPhotoWrapper>
      {availabilityTag && <StyledAvailabilityTag>{availabilityTag}</StyledAvailabilityTag>}
      <StyledGroceryDetailsWrapper>
        {title && (
          <StyledTitleWrapper>
            <Box mb={2}>
              <Typography
                testId={`${uuid}_grocery_item`}
                variant="heading16"
                isDisabled={!isAvailable}
                color={colors.positive.primary}
              >
                {title}
              </Typography>
            </Box>
          </StyledTitleWrapper>
        )}
        {highestDiscount && (
          <Tag variant="success" shape="circle" mb={2}>
            {highestDiscount}
          </Tag>
        )}
        {children}
      </StyledGroceryDetailsWrapper>
    </StyleGroceryCard>
  );
};

export default GroceryCard;
