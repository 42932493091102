import React from 'react';
import { Tag, useTheme } from '@zydalabs/storefront-components';
import { Typography, useTheme as useZacTheme } from '@zydalabs/zac-react';
import { NextImage } from 'common/components';
import { IMAGE_PRIORITY_INDEX_THRESHOLD } from 'common/constants';
import useTranslation from 'next-translate/useTranslation';
import {
  StyledDescriptionWrapper,
  StyledFoodCardDetailsWrapper,
  StyledFoodCardWrapper,
  StyledPhotoWrapper,
  StyledCountTagWrapper,
  StyledTitleWrapper,
} from './style';
import { ProductCardUIType } from '../ProductCard/types';

const FoodCard: ProductCardUIType = ({
  isAvailable,
  onClick,
  uuid,
  count,
  title,
  mayRequireExtraTimeText,
  description,
  highestDiscount,
  children,
  photoUrl,
  availabilityTag,
  index,
  isLowInStock,
  isQuickAddToCartEnabled,
}) => {
  const theme = useTheme();
  const { colors }: any = useZacTheme();
  const { t } = useTranslation('product');
  return (
    <StyledFoodCardWrapper onClick={onClick} isActive={!!count}>
      <StyledFoodCardDetailsWrapper>
        {count && !isQuickAddToCartEnabled && (
          <StyledCountTagWrapper>
            <Tag variant="primary" shape="circle" data-testid="item-count">
              {count}
            </Tag>
            <Typography testId={`${uuid}_food_item`} variant="body16" isDisabled={!isAvailable} color={colors.accent.primary}>
              {t('itemsAdded')}
            </Typography>
          </StyledCountTagWrapper>
        )}
        {title && (
          <StyledTitleWrapper hasPhoto={!!photoUrl} hasDescription={description}>
            <Typography testId={`${uuid}_food_item`} variant="heading18" isDisabled={!isAvailable} cursor="pointer">
              {title}
            </Typography>
          </StyledTitleWrapper>
        )}
        {description && (
          <StyledDescriptionWrapper>
            <Typography variant="body14" color={colors?.positiveAccent?.secondary} isDisabled={!isAvailable} cursor="pointer">
              {description}
            </Typography>
          </StyledDescriptionWrapper>
        )}
        {children}
        {!isAvailable && availabilityTag}
        {highestDiscount && isAvailable && (
          <Tag variant="success" shape="circle" mt={4} mb={1} data-testid="discounted-percentage">
            {highestDiscount}
          </Tag>
        )}
        {isLowInStock && !highestDiscount && availabilityTag}
        {mayRequireExtraTimeText && !availabilityTag && !highestDiscount && (
          <Tag variant="warning" shape="circle" color={theme.color.warning} mt={4} mb={1}>
            {mayRequireExtraTimeText}
          </Tag>
        )}
      </StyledFoodCardDetailsWrapper>
      {photoUrl && (
        <StyledPhotoWrapper dimImage={!isAvailable}>
          <NextImage
            alt={`${title}-image`}
            src={photoUrl}
            objectFit="cover"
            layout="fill"
            type="thumbnail"
            priority={index < IMAGE_PRIORITY_INDEX_THRESHOLD}
          />
        </StyledPhotoWrapper>
      )}
    </StyledFoodCardWrapper>
  );
};

export default FoodCard;
