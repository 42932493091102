import React from 'react';
import { ShimmerComponent } from '@zydalabs/storefront-components';

const FoodCardLoading = () => (
  <ShimmerComponent.ShimmerEffect>
    <ShimmerComponent.NoAnimationBox width="100%" justifyContent="space-between" p={4}>
      <ShimmerComponent.NoAnimationBox width="100%" flexDirection="column">
        <ShimmerComponent.NoAnimationBox>
          <ShimmerComponent.Rect width="30%" mb={2} />
        </ShimmerComponent.NoAnimationBox>
        <ShimmerComponent.NoAnimationBox>
          <ShimmerComponent.Rect width="90%" mb={2} />
        </ShimmerComponent.NoAnimationBox>
        <ShimmerComponent.NoAnimationBox>
          <ShimmerComponent.Rect width="30%" mb={2} />
        </ShimmerComponent.NoAnimationBox>
      </ShimmerComponent.NoAnimationBox>
      <ShimmerComponent.NoAnimationBox>
        <ShimmerComponent.Rect width="100px" height={100} />
      </ShimmerComponent.NoAnimationBox>
    </ShimmerComponent.NoAnimationBox>
  </ShimmerComponent.ShimmerEffect>
);

export default FoodCardLoading;
