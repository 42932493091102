import { Cart } from 'service/types/generated';
import { useCartSummary, useRemoveCartItem } from 'service/hooks';
import { useGlobalLoadingCart, useGlobalOrderTypeAndTime, usePersistedCartData } from 'common/hooks';
import { handleScheduledTime } from 'modules/productsModule/utils';

type RemoveFromCartType = (menuItemId: string) => Promise<void | Partial<Cart>>;

type UseRemoveFromCartType = () => RemoveFromCartType;

/**
 * A custom hook used to remove an item from the user cart
 *
 *  @returns Function removeFromCart
 * */

const useRemoveFromCart: UseRemoveFromCartType = () => {
  const [cartData] = usePersistedCartData();
  const [globalOrderTypeAndTime] = useGlobalOrderTypeAndTime();
  const [, setLoadingCart] = useGlobalLoadingCart();
  const { fulfillmentTimeType, scheduleSlotInterval } = globalOrderTypeAndTime || {};
  const scheduledTime = handleScheduledTime(fulfillmentTimeType, scheduleSlotInterval);

  const removeCartItem = useRemoveCartItem({ ...cartData });
  const { data } = useCartSummary({ ...cartData });

  const removeFromCart: RemoveFromCartType = async menuItemId => {
    if (!data) return;
    setLoadingCart(true);
    await removeCartItem(menuItemId, scheduledTime);
    setLoadingCart(false);
  };

  return removeFromCart;
};

export default useRemoveFromCart;
