import { CartItem } from 'service/types';

export const cartItemDetails = ({
  selectedCartItems,
  uuid,
}: {
  selectedCartItems: Partial<CartItem>[];
  uuid: string;
}): Partial<CartItem> => {
  let cartItem: Partial<CartItem> | Partial<CartItem>[] =
    selectedCartItems &&
    selectedCartItems.filter(({ menuItem }) => menuItem.uuid === uuid || menuItem.hiddenIdentifier === uuid);
  if (cartItem instanceof Array) {
    let totalQuantity = 0;
    cartItem.map(item => {
      if (item.variantQuantity) {
        totalQuantity += item.variantQuantity;
      }
      return item;
    });
    cartItem = {
      ...cartItem[0],
      variantQuantity: totalQuantity,
    };
  }
  return cartItem;
};
