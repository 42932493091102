import styled from 'styled-components';

export const StyledCounterWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: ${({ theme }) => theme.space[9]}px;
  ${({ isFoodCard }) => !isFoodCard && `justify-content: center; width: 100%;`}
`;

export const StyledPriceWrapper = styled.div`
  display: flex;
`;
