import styled from 'styled-components';

export const StyleFashionCard = styled.div<{ isGroceryCategory: boolean }>`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-width: 40%;
  ${({ isGroceryCategory }) => isGroceryCategory && `max-width: 40%`};
`;

export const StyledFashionCardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: ${({ theme }) => theme.space[2]}px;
  margin-top: ${({ theme }) => theme.space[2]}px;
  flex: 1;
`;

export const StyledPhotoWrapper = styled.div<{ dimImage: boolean }>`
  ${({ dimImage }) => dimImage && `filter: opacity(50%);`};
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  @supports not (aspect-ratio: 1) {
    padding-top: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
  }
  img {
    border-radius: ${({ theme }) => theme.space[2]}px;
    position: unset !important;
    width: 100% !important;
  }
`;

export const StyledImageLoadingWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${({ theme }) => theme.space[2]}px;
  border-radius: ${({ theme }) => theme.space[2]}px;
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const StyledTextTitleWrapper = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.space[1]}px;
`;
