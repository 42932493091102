import React from 'react';
import { ShimmerComponent } from '@zydalabs/storefront-components';
import { StyledImageLoadingWrapper } from './style';

const FashionCardUILoading = () => (
  <div key="fashionCarUILoading">
    <ShimmerComponent.NoAnimationBox width="100%" flexDirection="column">
      <StyledImageLoadingWrapper>
        <ShimmerComponent.Rect width="164" height={164} />
      </StyledImageLoadingWrapper>
      <ShimmerComponent.Rect width="80%" height={20} />
    </ShimmerComponent.NoAnimationBox>
  </div>
);
export default FashionCardUILoading;
