import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { isNil } from 'ramda';
import useTranslation from 'next-translate/useTranslation';
import { Icon, useTheme, Tag } from '@zydalabs/storefront-components';

import { IcTag } from 'common/icons';
import { useGlobalOrderTypeAndTime } from 'common/hooks';
import { LANGUAGE_AR, LAYOUT_ONE, LAYOUT_THREE, LAYOUT_TWO } from 'common/constants';
import { useProductDetails, useStockThresholdSettings } from 'service/hooks';
import { checkIfAllVariantsAreAvailable, discountTagText, handleScheduledTime } from 'modules/productsModule/utils';
import { useFulfillmentSettings } from 'contexts/fulfillmentSettings/context';
import { localizeNumber } from 'common/utils';
import { ICardType, ProductComponentMapType, ProductLoadingComponentMapType } from './types';
import { trimText, sanitizeTitleString } from '../../utils';
import FoodCard from '../FoodCard';
import FashionCard from '../FashionCard';
import GroceryCard from '../GroceryCard';
import CardButton from '../CardButton';
import FoodCardLoading from '../FoodCard/loading';
import FashionCardUILoading from '../FashionCard/loading';
import GroceryCardUILoading from '../GroceryCard/loading';

const productComponentMap: ProductComponentMapType = {
  [LAYOUT_ONE]: FoodCard,
  [LAYOUT_TWO]: FashionCard,
  [LAYOUT_THREE]: GroceryCard,
};

const productLoadingComponentMap: ProductLoadingComponentMapType = {
  [LAYOUT_ONE]: FoodCardLoading,
  [LAYOUT_TWO]: FashionCardUILoading,
  [LAYOUT_THREE]: GroceryCardUILoading,
};

const ProductCard: React.FC<ICardType> = ({
  productLayout,
  uuid,
  category,
  searchProduct,
  isLoading,
  onClick = () => null,
  cartItem,
  defaultPrepTime,
  isGroceryCategory = false,
}) => {
  const theme = useTheme();
  const router = useRouter();
  const { t, lang } = useTranslation('product');
  // persisted data
  const { orderMode: mode, branch } = useFulfillmentSettings();

  // states
  const [shouldInvalidateProduct, setShouldInvalidateProduct] = useState<boolean>(false);

  // hooks
  const { data: stockThresholdSettings } = useStockThresholdSettings();
  const [globalOrderTypeAndTime] = useGlobalOrderTypeAndTime();
  const { fulfillmentTimeType, scheduleSlotInterval } = globalOrderTypeAndTime || {};
  const scheduledTime = handleScheduledTime(fulfillmentTimeType, scheduleSlotInterval);

  const { data: productDetails } = useProductDetails({
    uuid,
    ...(!!scheduledTime && { scheduledTime }),
    branchId: branch?.id,
    shouldInvalidate: shouldInvalidateProduct,
  });

  const { variantQuantity = 0, id: cartItemId } = cartItem || {};
  const { titleAr, titleEn, photoUrl, uuid: uuidProduct } = category || productDetails || searchProduct || {};
  const {
    descriptionAr,
    descriptionEn,
    displayPrice,
    price,
    highestDiscountedPercentage,
    variants,
    quickAddToCart,
    fulfillmentMode,
    maxPrepTime,
    stockData: productStockData,
    variants: productVariants,
  } = productDetails || searchProduct || {};

  const isQuickAddToCartEnabled = quickAddToCart && !isNil(branch?.id);
  const firstVariant = variants?.[0];

  const isProduct = isNil(category);

  const { availabilityText, availabilityTagVariant, unavailabilityReason, stockCount, trackingType, isLowInStock } =
    checkIfAllVariantsAreAvailable({
      productVariants,
      t,
      lang,
      shouldInvalidateProduct,
      productFulfillmentMode: fulfillmentMode,
      orderMode: mode,
      stockThresholdSettings,
      productStockData: isQuickAddToCartEnabled ? firstVariant?.stockData : productStockData,
    });

  const isArabic = router.locale === LANGUAGE_AR;
  const title = isArabic ? titleAr : titleEn;
  const description = isArabic ? descriptionAr : descriptionEn;
  const CardLoading = productLoadingComponentMap[productLayout];
  const Card = productComponentMap[productLayout];
  const isFoodCard = productLayout === LAYOUT_ONE;

  if (isLoading) return <CardLoading />;

  const hasExtraPrepTime = maxPrepTime > defaultPrepTime;

  return (
    <Card
      uuid={uuidProduct}
      onClick={onClick}
      mayRequireExtraTimeText={hasExtraPrepTime && !unavailabilityReason && t('mayRequireExtraTime')}
      count={variantQuantity > 0 && `${localizeNumber(lang, variantQuantity)}`}
      isAvailable={!unavailabilityReason}
      title={sanitizeTitleString(title)}
      isGroceryCategory={isGroceryCategory}
      description={trimText(description, 75)}
      highestDiscount={
        highestDiscountedPercentage && (
          <>
            <Icon
              icon={IcTag}
              color={theme.color.black}
              width={16}
              margin={isArabic ? `0 0 0 ${theme.space[1]}px` : `0 ${theme.space[1]}px 0 0`}
            />
            {discountTagText(t, isQuickAddToCartEnabled, lang, highestDiscountedPercentage)}
          </>
        )
      }
      photoUrl={photoUrl}
      availabilityTag={
        availabilityText && (
          <Tag
            variant={availabilityTagVariant}
            shape="circle"
            data-testid="product-availability"
            mt={isFoodCard && 4}
            mb={isFoodCard && 1}
          >
            {availabilityText}
          </Tag>
        )
      }
      isLowInStock={isLowInStock}
      isCategoryPage={router.pathname !== '/'}
      isQuickAddToCartEnabled={isQuickAddToCartEnabled}
    >
      {isProduct && (
        <CardButton
          uuid={uuid}
          isProductUnAvailable={!!unavailabilityReason}
          stockCount={trackingType && stockCount}
          displayPrice={displayPrice}
          isQuickAddToCartEnabled={isQuickAddToCartEnabled}
          cartItemId={cartItemId}
          mode={mode}
          variantQuantity={variantQuantity}
          firstVariantId={firstVariant?.id}
          firstVariantName={firstVariant?.titleEn}
          setShouldInvalidateProduct={setShouldInvalidateProduct}
          price={price}
          firstVariantPrice={firstVariant?.price}
          highestDiscountedPercentage={highestDiscountedPercentage}
          title={title}
          isFoodCard={isFoodCard}
        />
      )}
    </Card>
  );
};

export default ProductCard;
