import React from 'react';
import { ShimmerComponent } from '@zydalabs/storefront-components';

import { StyleGroceryCard } from './style';

const GroceryCardUILoading = () => (
  <StyleGroceryCard isLoading>
    <ShimmerComponent.ShimmerEffect>
      <ShimmerComponent.NoAnimationBox>
        <ShimmerComponent.NoAnimationBox width="160px" height={234} flexDirection="column">
          <ShimmerComponent.Rect width="160px" height={120} mb={2} borderRadius={1} />
          <ShimmerComponent.Rect width="70%" height={20} mt={2} />
          <ShimmerComponent.Rect width="90%" height={20} mt={2} />
          <ShimmerComponent.Rect width="80%" height={20} mt={2} />
          <ShimmerComponent.Rect width="80%" height={20} mt={5} />
        </ShimmerComponent.NoAnimationBox>
      </ShimmerComponent.NoAnimationBox>
    </ShimmerComponent.ShimmerEffect>
  </StyleGroceryCard>
);

export default GroceryCardUILoading;
