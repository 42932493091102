import React from 'react';
import { Tag } from '@zydalabs/storefront-components';

import { NextImage } from 'common/components';
import { StyledAvailabilityTag } from 'common/styles';
import { IMAGE_PRIORITY_INDEX_THRESHOLD } from 'common/constants';
import { Typography, useTheme as useZacTheme, Box } from '@zydalabs/zac-react';
import {
  StyledFashionCardContentContainer,
  StyledPhotoWrapper,
  StyleFashionCard,
  StyledTitleWrapper,
  StyledTextTitleWrapper,
  StyledButtonWrapper,
} from './style';
import { ProductCardUIType } from '../ProductCard/types';

const FashionCard: ProductCardUIType = ({
  onClick,
  uuid,
  count,
  isAvailable,
  title,
  highestDiscount,
  children,
  photoUrl,
  availabilityTag,
  index,
  isQuickAddToCartEnabled,
  isGroceryCategory,
  isCategoryPage,
}) => {
  const { colors } = useZacTheme();

  return (
    <StyleFashionCard onClick={onClick} isGroceryCategory={isGroceryCategory && !isCategoryPage}>
      <StyledPhotoWrapper dimImage={!isAvailable}>
        <NextImage
          alt={`${title}-image`}
          src={photoUrl}
          objectFit="cover"
          layout="fill"
          type="thumbnail"
          priority={index < IMAGE_PRIORITY_INDEX_THRESHOLD}
        />
      </StyledPhotoWrapper>
      <StyledFashionCardContentContainer>
        {availabilityTag && <StyledAvailabilityTag>{availabilityTag}</StyledAvailabilityTag>}
        <StyledTitleWrapper>
          {count && !isQuickAddToCartEnabled && (
            <Typography variant="heading16" color={colors.accent.primary} isDisabled={!isAvailable}>
              {count}x
            </Typography>
          )}
          {title && (
            <Box mx={1}>
              <StyledTextTitleWrapper>
                <Typography testId={`${uuid}_fashion_item`} variant="heading16" isDisabled={!isAvailable}>
                  {title}
                </Typography>
              </StyledTextTitleWrapper>
            </Box>
          )}
        </StyledTitleWrapper>
        {highestDiscount && (
          <Tag variant="success" shape="circle" mt={3}>
            {highestDiscount}
          </Tag>
        )}
      </StyledFashionCardContentContainer>
      <StyledButtonWrapper>{children}</StyledButtonWrapper>
    </StyleFashionCard>
  );
};

export default FashionCard;
