import React, { ReactChild } from 'react';

const IcTag: () => ReactChild = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7.24671L8.75 14L14 8.73827L7.25 1.99998H2V7.24671ZM6.74997 5.62473C6.74997 6.24604 6.2463 6.74971 5.62498 6.74971C5.00367 6.74971 4.5 6.24604 4.5 5.62473C4.5 5.00341 5.00367 4.49974 5.62498 4.49974C6.2463 4.49974 6.74997 5.00341 6.74997 5.62473Z"
      fill="currentColor"
    />
  </svg>
);

export default IcTag;
