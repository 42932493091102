import styled from 'styled-components';

export const StyledFoodCardWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.space[5]}px ${theme.space[5]}px`};
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  ${({ isActive, theme }) =>
    isActive &&
    `
  &:before {
    content: '';
    top: 0px;
    bottom: 0px;
    position: absolute;
    height: 100%;
    width: 4px;
    background-color: ${theme.color.primary};
    margin-inline-start: -20px;
  }  
`}
`;

export const StyledFoodCardDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  margin-inline-end: ${({ theme }) => theme.space[5]}px;
`;

// TODO: Remove wrapper if image shouldn't be responsive
export const StyledPhotoWrapper = styled.div<{ dimImage: boolean }>`
  display: flex;
  align-items: center;
  width: 30%;
  max-width: 100px;
  min-width: 100px;
  height: fit-content;
  ${({ dimImage }) => dimImage && `filter: opacity(50%);`}
  position: relative;
  & :after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  & div,
  img {
    border-radius: ${({ theme }) => theme.space[2]}px;
    aspect-ratio: 1;
    @supports not (aspect-ratio: 1) {
      padding-top: 100%;
      height: 0;
      position: relative;
      overflow: hidden;
    }
    width: 100%;
  }
`;

export const StyledDescriptionWrapper = styled.div`
  word-break: break-word;
  margin-bottom: ${({ theme }) => theme.space[3]}px;
`;

export const StyledCountTagWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing['8']};
  margin-bottom: ${({ theme }) => theme.space[4]}px;
`;

export const StyledTitleWrapper = styled.div<{ hasPhoto: boolean }>`
  ${({ hasPhoto, hasDescription, theme }) => `
    width: ${hasPhoto ? '60vw' : '90vw'};
    margin-bottom: ${hasDescription ? `${theme.space[1]}px` : `${theme.space[3]}px`};
  `}
  @media (min-width: calc(${props => props.theme.screenBreakpoints.large} + 0.2px)) {
    width: 290px;
  }
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;
